import React, { useContext, useEffect, useRef, useState } from "react"
import axios from "axios"
import {
  MailDomain,
  MailSender,
  RecipientAlias,
  SenderPerMonth,
} from "../models/MailDomain"
import { not_null } from "../helpers/emg"
import { useLocation, useNavigate, useParams } from "react-router-dom"
import { Field, Form, Formik, useFormikContext } from "formik"
import TextField from "../helpers/TextField"
import { useErrorHandler } from "react-error-boundary"
import routes from "../routes"
import BreadcrumbContext from "../BreadcrumbContext"
import { updateBreadcrumbs } from "../helpers/updateBreadcrumbs"
import PrimaryButton from "../helpers/PrimaryButton"
import {
  useEMGUsersWithCompany,
  useMailDomain,
  useMailDomainRecipient,
  useMailDomainSender,
} from "../helpers/hooks"
import { useMutation, useQueryClient } from "react-query"
import UserContext from "../UserContext"
import SelectEMGAccount from "../helpers/SelectEMGAccount"
import Checkbox from "../helpers/Checkbox"
import { useLocalStorage } from "../helpers/useLocalStorage"
import FilterField from "../helpers/FilterField"
import _ from "lodash"
import { InvalidSenders } from "./InvalidSenders"

enum SenderSortFields {
  SENDER_FROM,
  SENDER_CC,
  SENDER_BALANCE,
  SENDER_LAST_SMS,
}

const MailDomainEdit = () => {
  const appuser = useContext(UserContext)
  const { id } = useParams<string>()
  const navigate = useNavigate()
  const role = appuser.role.toLowerCase()
  const { maildomain, loading: maildomain_loading } = useMailDomain(role, id!)
  const { accounts, loading: accounts_loading } = useEMGUsersWithCompany(role)
  const handleError = useErrorHandler()
  const cache = useQueryClient()
  const [selectedSender, setSelectedSender] = useState(-1)
  const [selectedRecipient, setSelectedRecipient] = useState(-1)
  const [senderFilter, setSenderFilter] = useState("")
  const [recipientFilter, setRecipientFilter] = useState("")
  const [senderSortField, setSenderSortField] = useLocalStorage(
    "MailDomainEdit.senderSortField",
    SenderSortFields.SENDER_FROM
  )
  const { sender } = useMailDomainSender(role, id!, selectedSender)
  const { recipient } = useMailDomainRecipient(role, id!, selectedRecipient)
  const loading = maildomain_loading || accounts_loading
  const nameRef = useRef(null)
  const [savedMsg, setSavedMsg] = useState("")
  const [savedSenderMsg, setSavedSenderMsg] = useState("")
  const [savedRecipientMsg, setSavedRecipientMsg] = useState("")

  const context = useContext(BreadcrumbContext)
  const location = useLocation()
  useEffect(() => {
    updateBreadcrumbs(context, location, [
      {
        text: "Maildomains",
        path: routes.admin.root + routes.admin.MailDomains,
      },
      { text: "MailDomain" },
    ])
  }, [context, location])

  const beforeSave = () => {
    setSavedMsg("")
    setSavedSenderMsg("")
    setSavedRecipientMsg("")
  }

  const { mutate: updateMailDomain } = useMutation(async (values: object) => {
    beforeSave()
    const fields = _.pick(values, [
      "domain",
      "maxlen",
      "username",
      "allowedsender",
      "sourceaddr",
      "usefrom",
      "usesubject",
      "sat",
      "dlr_footer",
      "report_address",
      "cc_text",
    ])
    await axios.post("/api/" + role + "/maildomain/" + id, fields)
    setSavedMsg("Mail domain updated.")
    await cache.invalidateQueries("maildomain")
  })

  const { mutate: updateMailSender } = useMutation(async (values: object) => {
    beforeSave()
    const fields = _.pick(values, [
      "prepaid_pdus",
      "from_address",
      "name",
      "sourceaddr",
      "cost_center",
      "itemno",
      "confirm_fails_only",
      "packsize",
    ])
    console.log("Update mail sender:", JSON.stringify(fields))
    await axios.post(
      "/api/" + role + "/maildomain/" + maildomain.id + "/sender/" + sender.id,
      fields
    )
    setSavedSenderMsg("Sender saved.")
    await cache.invalidateQueries("maildomain_sender")
    await cache.invalidateQueries("maildomain")
  })

  const { mutate: updateMailRecipient } = useMutation(
    async (values: object) => {
      beforeSave()
      const fields = _.pick(values, ["alias", "addresses"])
      console.log("Update mail recipient:", JSON.stringify(fields))
      await axios.post(
        "/api/" +
          role +
          "/maildomain/" +
          maildomain.id +
          "/recipient/" +
          recipient.id,
        fields
      )
      setSavedRecipientMsg("Recipient saved.")
      await cache.invalidateQueries("maildomain_recipient")
      await cache.invalidateQueries("maildomain")
    }
  )

  const { mutate: addNewMailSender } = useMutation(
    async (values: MailSender) => {
      beforeSave()
      const fields = {
        from_address: values.from_address,
      }
      console.log("Add mail sender:", JSON.stringify(fields))
      await axios.post(
        "/api/" + role + "/maildomain/" + maildomain.id + "/sender/0",
        fields
      )
      await cache.invalidateQueries("maildomain")
    }
  )

  const { mutate: addNewMailRecipient } = useMutation(
    async (values: RecipientAlias) => {
      beforeSave()
      const fields = {
        alias: values.alias,
      }
      console.log("Add mail recipient:", JSON.stringify(fields))
      await axios.post(
        "/api/" + role + "/maildomain/" + maildomain.id + "/recipient/0",
        fields
      )
      await cache.invalidateQueries("maildomain")
    }
  )

  const [saveSender, setSaveSender] = useState(false)

  const AutoAddSender = () => {
    const { values, submitForm } = useFormikContext()
    React.useEffect(() => {
      if (saveSender) {
        setSaveSender(false)
        submitForm()
      }
    }, [values, submitForm])
    return null
  }

  if (loading) return <>{loading}</>

  const submitData = async (values: Object, setSubmitting: any) => {
    console.log("saving " + JSON.stringify(values))
    try {
      await updateMailDomain(values)
    } catch (error) {
      console.log("post.error " + JSON.stringify(error))
      // @ts-ignore
      handleError(error)
    } finally {
      setSubmitting(false)
      await cache.invalidateQueries("maildomain")
      if (maildomain.id < 1) navigate("..")
    }
  }

  const separateSender = async (spm_id: number) => {
    console.log("separate", JSON.stringify(spm_id))
    try {
      await axios.post("/api/" + role + "/maildomain/separate/" + spm_id)
    } catch (error) {
      console.log("post.error " + JSON.stringify(error))
      // @ts-ignore
      handleError(error)
    }
    return cache.invalidateQueries("maildomain")
  }

  const initial_maildomain = () => {
    return {
      ...not_null(maildomain),
      new_sender: "",
      new_alias: "",
    } as MailDomain
  }

  const filter_sender = (entry: MailSender) => {
    return (
      entry.from_address.toLowerCase().indexOf(senderFilter.toLowerCase()) >
        -1 ||
      entry.name.toLowerCase().indexOf(senderFilter.toLowerCase()) > -1 ||
      entry.cost_center.toLowerCase().indexOf(senderFilter.toLowerCase()) > -1
    )
  }

  const updateSenderFilter = (e: any) => {
    setSenderFilter(e.target.value)
    setSelectedSender(-1)
  }

  const filter_recipient = (entry: RecipientAlias) => {
    const lowerCaseFilter = recipientFilter.toLowerCase()
    return (
      entry.alias.toLowerCase().indexOf(lowerCaseFilter) > -1 ||
      entry.addresses.toLowerCase().indexOf(lowerCaseFilter) > -1
    )
  }

  const updateRecipientFilter = (e: any) => {
    setRecipientFilter(e.target.value)
    setSelectedRecipient(-1)
  }

  const can_submit_sender = (values: MailSender) => {
    if (("" + values.from_address).length < 1) return false
    return true
  }

  const can_submit = (values: MailDomain) => {
    if (("" + values.domain).length < 1) return false
    if (("" + values.username).length < 1) return false
    return true
  }

  const can_add_sender = (values: MailSender) => {
    return values.from_address.length > 0
  }

  const can_add_alias = (values: RecipientAlias) => {
    return values.alias.length > 0
  }

  const format_month = (year: number, month: number) => {
    return year + "-" + (month < 10 ? "0" : "") + month
  }

  const adjusted_month = (year: number, month: number, offset: number) => {
    let y1 = year
    let m1 = month - offset
    if (m1 < 1) {
      m1 = m1 + 12
      y1 = y1 - 1
    }
    return format_month(y1, m1)
  }

  const add_latest_month = (ms: MailSender) => {
    const months = ms.sender_per_month || []
    let latest_month = ""
    months.forEach((spm: SenderPerMonth) => {
      if (spm.month > latest_month) latest_month = spm.month
    })
    return { ...ms, latest_month }
  }

  const sender_cmp = (s1: MailSender, s2: MailSender) => {
    if (senderSortField === SenderSortFields.SENDER_LAST_SMS) {
      if (s1.latest_month < s2.latest_month) return 1
      if (s1.latest_month > s2.latest_month) return -1
    }
    if (senderSortField === SenderSortFields.SENDER_BALANCE) {
      if (s1.prepaid_pdus < s2.prepaid_pdus) return -1
      if (s1.prepaid_pdus > s2.prepaid_pdus) return 1
    }
    if (senderSortField === SenderSortFields.SENDER_CC) {
      if (s1.cost_center < s2.cost_center) return -1
      if (s1.cost_center > s2.cost_center) return 1
    }
    if (s1.from_address < s2.from_address) return -1
    if (s1.from_address > s2.from_address) return 1
    return s2.index - s1.index
  }

  const submitMailSender = async (values: Object) => {
    try {
      await updateMailSender(values)
    } catch (error) {
      console.log("post.error " + JSON.stringify(error))
      // @ts-ignore
      handleError(error)
    }
  }

  const submitNewMailSender = async (values: MailSender) => {
    try {
      await addNewMailSender(values)
    } catch (error) {
      console.log("post.error " + JSON.stringify(error))
      // @ts-ignore
      handleError(error)
    }
  }

  const submitMailRecipient = async (values: Object) => {
    try {
      await updateMailRecipient(values)
    } catch (error) {
      console.log("post.error " + JSON.stringify(error))
      // @ts-ignore
      handleError(error)
    }
  }

  const submitNewMailRecipient = async (values: RecipientAlias) => {
    try {
      await addNewMailRecipient(values)
    } catch (error) {
      console.log("post.error " + JSON.stringify(error))
      // @ts-ignore
      handleError(error)
    }
  }

  const EditSenderDetails = ({ ms }: { ms: MailSender }) => {
    function allowedSenders() {
      return (
        <label>
          Incoming sender email address or domain (the latter including the
          '@'). Multiple addresses are allowed, separated by a comma.
          <Field
            className={"form-control form-control-sm col"}
            type={"text"}
            name={"from_address"}
          />
        </label>
      )
    }

    function outgoingSourceAddress() {
      return (
        <label>
          Outgoing source address (max 11 characters, no spaces)
          <Field
            className={"form-control form-control-sm col"}
            type={"text"}
            name={"sourceaddr"}
          />
        </label>
      )
    }

    function senderName() {
      return (
        <label>
          Name
          <Field
            className={"form-control form-control-sm col"}
            type={"text"}
            name={"name"}
            size={30}
          />
        </label>
      )
    }

    function costCenter() {
      return (
        <label>
          Cost center, customer number, etc
          <Field
            className={"form-control form-control-sm col"}
            type={"text"}
            name={"cost_center"}
          />
        </label>
      )
    }

    function packSize() {
      return (
        <label>
          Pack size
          <Field
            className={"form-control form-control-sm col"}
            name={"packsize"}
            field_type={"number"}
            min_number={0}
          />
        </label>
      )
    }

    function itemNo() {
      return (
        <label>
          Item no
          <Field
            className={"form-control form-control-sm col"}
            type={"text"}
            name={"itemno"}
          />
        </label>
      )
    }

    return (
      <div hidden={!ms || !ms.maildomain_id}>
        <Formik
          initialValues={ms || new MailSender()}
          onSubmit={async (values, { setSubmitting }) => {
            setSubmitting(false)
            return submitMailSender(values)
          }}
        >
          {({ isSubmitting, values }) => (
            <Form className="form">
              <div className={"row"}>{allowedSenders()}</div>
              <div className={"row"}>{outgoingSourceAddress()}</div>
              <div className={"row"}>{senderName()}</div>
              {/*
          <div className={"row"}>
            <label>
              Optionally, another EMG account
              <SelectEMGAccount
                label={""}
                name={`senders.${selectedSender}.emguser_id`}
                accounts={accounts.data}
                nullText={"Same as above"}
              />
            </label>
          </div>
        */}
              <div className={"row"}>{costCenter()}</div>
              <div className={"row"}>{packSize()}</div>
              <div className={"row"}>{itemNo()}</div>
              <Checkbox
                label={"Confirm fails only"}
                name={"confirm_fails_only"}
              />
              <div className={"row"}>
                <div className={"col"}>
                  <label>Current credit balance:</label>&nbsp;
                  {ms ? ms.prepaid_pdus : ""}
                </div>
              </div>
              {ms && ms.invoiced_on && (
                <div className={"row"}>
                  <div className={"col"}>
                    Most recent invoice: {"" + ms.invoiced_on}
                  </div>
                </div>
              )}
              <div className={"row mt-4 h-100"}>
                <div className={"col-2"}>
                  <PrimaryButton
                    text={"Save"}
                    isSubmitting={isSubmitting}
                    disabled={!can_submit_sender(values)}
                  />
                </div>
                <div className={"col my-auto"}>{savedSenderMsg}</div>
              </div>
            </Form>
          )}
        </Formik>
        <div className={"row mt-4"}>
          {showBalancePerMonth(ms ? ms.sender_per_month : [])}
        </div>
        <div className={"row mt-4"}>
          {showUsersPerMonth(ms ? ms.sender_per_month : [])}
        </div>
      </div>
    )
  }

  function showUsersPerMonth(values: Array<SenderPerMonth>) {
    return (
      (values || []).length > 0 && (
        <table className={"col table table-sm table-stripe"}>
          <thead>
            <tr>
              <th>Month</th>
              <th>Sender</th>
              <th>&nbsp;</th>
              <th>Count</th>
            </tr>
          </thead>
          <tbody>
            {values.map((entry: SenderPerMonth, index: number) => {
              const single =
                maildomain.senders.find((sender: MailSender) => {
                  return sender.from_address === entry.email
                }) !== undefined
              return (
                <tr key={index}>
                  <td>{entry.month}</td>
                  <td>{entry.email}</td>
                  <td>
                    {single || (
                      <button
                        className={"btn btn-primary btn-sm"}
                        type={"button"}
                        onClick={async () => {
                          return separateSender(entry.id)
                        }}
                      >
                        Separate
                      </button>
                    )}
                  </td>
                  <td>{entry.count}</td>
                </tr>
              )
            })}
          </tbody>
        </table>
      )
    )
  }

  function showBalancePerMonth(values: Array<SenderPerMonth>) {
    const date = new Date()
    const current_year = date.getFullYear()
    let current_month = date.getMonth() + 1
    const month = format_month(current_year, current_month)
    const m1 = adjusted_month(current_year, current_month, 1)
    const m2 = adjusted_month(current_year, current_month, 2)
    const m3 = adjusted_month(current_year, current_month, 3)
    let countPerMonth = new Map()
    ;(values || [])
      .filter((entry: SenderPerMonth) => {
        return entry.month >= m3
      })
      .forEach((entry: SenderPerMonth) => {
        let userEntry = countPerMonth.get(entry.email) || {}
        countPerMonth.set(entry.email, {
          ...userEntry,
          [entry.month]: entry.count,
        })
      })

    return (
      <>
        <table className={"col table table-sm table-stripe"}>
          <thead>
            <tr>
              <th>Email</th>
              <th>{month}</th>
              <th>{m1}</th>
              <th>{m2}</th>
              <th>{m3}</th>
            </tr>
          </thead>
          <tbody>
            {Array.from(countPerMonth)
              .sort((e1: any, e2: any) => {
                if (e1[0] < e2[0]) return -1
                if (e1[0] > e2[0]) return 1
                return 0 // can never happen
              })
              .map((entry: any, index: number) => {
                const values = entry[1]
                return (
                  <tr key={index}>
                    <td>{entry[0]}</td>
                    <td>{values[month] || "0"}</td>
                    <td>{values[m1] || "0"}</td>
                    <td>{values[m2] || "0"}</td>
                    <td>{values[m3] || "0"}</td>
                  </tr>
                )
              })}
          </tbody>
        </table>
      </>
    )
  }

  const EditRecipientDetails = ({
    recipient,
  }: {
    recipient: RecipientAlias
  }) => {
    return (
      <div hidden={!recipient || !recipient.id}>
        <Formik
          initialValues={recipient || new RecipientAlias()}
          onSubmit={async (values, { setSubmitting }) => {
            setSubmitting(false)
            return submitMailRecipient(values)
          }}
        >
          {({ isSubmitting, values }) => (
            <Form className="form">
              <div className={"row"}>
                <label>
                  Name (handles emails to {values.alias}@{maildomain.domain})
                  <Field
                    className={"form-control form-control-sm col"}
                    type={"text"}
                    name={"alias"}
                  />
                </label>
              </div>
              <div className={"row"}>Recipient list</div>
              <div className={"row"}>
                <Field
                  className={"form-control form-control-sm col"}
                  as={"textarea"}
                  name={"addresses"}
                  rows={10}
                  cols={40}
                  // Things that do not work to get rid of the
                  // onloadwff warning from LastPass:
                  // data-lpignore={"true"}
                  // type={"search"}
                  // autoComplete="off"
                />
              </div>
              <div className={"row mt-3 h-100"}>
                <div className={"col-2"}>
                  <PrimaryButton
                    text={"Save"}
                    isSubmitting={isSubmitting}
                    //disabled={!can_submit_sender(values)}
                  />
                </div>
                <div className={"col my-auto"}>{savedRecipientMsg}</div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    )
  }

  function ShowMailSender(sender: MailSender) {
    if (!filter_sender(sender)) return <tr key={sender.id} hidden={true} />
    return (
      <tr
        key={sender.id}
        className={(sender.id === selectedSender && "table-success") || ""}
      >
        <td
          onClick={() => {
            beforeSave()
            setSelectedSender(sender.id)
            setSelectedRecipient(-1)
          }}
        >
          {sender.from_address}
        </td>
        <td>{sender.cost_center}</td>
        <td>{sender.prepaid_pdus}</td>
        <td>{sender.latest_month}</td>
        <td>
          {/*role === "owner" && (
            <img
              src="/delete.png"
              alt={"delete sender " + sender.from_address}
              onClick={() => {
                setSelectedSender(-1)
              }}
            />
          )*/}
        </td>
      </tr>
    )
  }

  return (
    <>
      <Formik
        initialValues={initial_maildomain()}
        onSubmit={async (values, { setSubmitting }) => {
          return submitData(values, setSubmitting)
        }}
      >
        {({ isSubmitting, values, setFieldValue, setSubmitting }) => (
          <>
            <AutoAddSender />
            <Form className={"form"}>
              <TextField
                label="Domain Name"
                sublabel={"something.example.com, no leading @"}
                name="domain"
                required={true}
                ref={nameRef}
              />

              <SelectEMGAccount
                label={"EMG User"}
                name={"username"}
                required={true}
                by_name={true}
                accounts={accounts.data}
                nullText={"Select"}
              />

              <TextField
                label="Allowed Sender"
                name="allowedsender"
                required={true}
                sublabel={"user@example.se, @example.se, or *"}
              />

              {/* TODO: Enforce max 14 digits, or 11 alphanumeric */}
              <TextField
                label="Source Address"
                sublabel={
                  "Allowed characters: A-Z, a-z, 0-9, åäö, ÅÄÖ." +
                  " Max 11 characters."
                }
                name="sourceaddr"
              />

              <TextField
                label={"Maximum message length"}
                name={"maxlen"}
                field_type={"number"}
              />

              <Checkbox
                label={"Prepend the message with the original From address"}
                name={"usefrom"}
              />

              <Checkbox
                label={"Prepend the message with the incoming Subject"}
                name={"usesubject"}
              />

              <Checkbox
                label={"Enable replies to the original email sender"}
                name={"sat"}
              />

              <TextField
                label={"Delivery report footer"}
                sublabel={
                  "A delivery report email will be sent back if" +
                  " this text is non-empty."
                }
                name={"dlr_footer"}
                rows={3}
                tag={"textarea"}
              />

              <TextField
                label={"Email address for reports about new senders"}
                name={"report_address"}
              />

              <TextField
                label={
                  "Additional text intended for the sender, giving them" +
                  " a Cc of the email sent to the address above."
                }
                name={"cc_text"}
                rows={3}
                tag={"textarea"}
                disabled={values.report_address.length === 0}
              />

              <div className={"row h-100"}>
                <div className={"col-3"}></div>
                <div className={"col-1 my-auto"}>
                  <PrimaryButton
                    text={"Save"}
                    isSubmitting={isSubmitting}
                    disabled={!can_submit(values)}
                  />
                </div>
                <div className={"col my-auto"}>{savedMsg}</div>
              </div>
            </Form>
          </>
        )}
      </Formik>

      <h2>Senders</h2>

      <InvalidSenders maildomain={maildomain} />

      <FilterField valueProp={senderFilter} setValueProp={updateSenderFilter} />

      <div className={"row"}>
        <div className={"col-5 mr-4"}>
          <Formik
            initialValues={new MailSender()}
            onSubmit={async (values, { setSubmitting }) => {
              console.log("Add sender", JSON.stringify(values))
              setSubmitting(false)
              return submitNewMailSender(values)
            }}
          >
            {({ isSubmitting, values, setFieldValue, setSubmitting }) => (
              <>
                <Form className={"form"}>
                  <table className="table table-sm table-hover">
                    <thead>
                      <tr>
                        <th
                          onClick={() => {
                            setSenderSortField(SenderSortFields.SENDER_FROM)
                          }}
                        >
                          Name
                        </th>
                        <th
                          onClick={() => {
                            setSenderSortField(SenderSortFields.SENDER_CC)
                          }}
                        >
                          Cost center
                        </th>
                        <th
                          onClick={() => {
                            setSenderSortField(SenderSortFields.SENDER_BALANCE)
                          }}
                        >
                          Balance
                        </th>
                        <th
                          onClick={() => {
                            setSenderSortField(SenderSortFields.SENDER_LAST_SMS)
                          }}
                        >
                          Last SMS
                        </th>
                        <th>{/* Delete */}</th>
                      </tr>
                    </thead>

                    <tbody>
                      {(maildomain.senders || [])
                        .map(add_latest_month)
                        .sort(sender_cmp)
                        .map((s: MailSender) => {
                          const sender = {
                            ...s,
                            confirm_fails_only: !!s.confirm_fails_only,
                          }
                          return ShowMailSender(sender)
                        })}

                      <tr>
                        <td>
                          <Field
                            className={"form-control form-control-sm col"}
                            type={"text"}
                            name={"from_address"}
                            placeholder={"new sender address"}
                          />
                        </td>
                        <td>&nbsp;</td>
                        <td>
                          <PrimaryButton
                            text={"Add"}
                            isSubmitting={isSubmitting}
                            disabled={!can_add_sender(values)}
                          />
                        </td>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                      </tr>
                    </tbody>
                  </table>
                </Form>
              </>
            )}
          </Formik>
        </div>
        <div className={"col"}>
          <EditSenderDetails ms={sender} />
        </div>
      </div>

      <h2>Recipients</h2>
      <FilterField
        valueProp={recipientFilter}
        setValueProp={updateRecipientFilter}
      />

      <div className={"row"}>
        <div className={"col-4"}>
          <Formik
            initialValues={new RecipientAlias()}
            onSubmit={async (values, { setSubmitting }) => {
              console.log("Add recipient", JSON.stringify(values))
              setSubmitting(false)
              return submitNewMailRecipient(values)
            }}
          >
            {({ isSubmitting, values }) => (
              <>
                <Form className={"form"}>
                  <table className="table table-sm table-hover">
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>Add{/*/Delete*/}</th>
                      </tr>
                    </thead>

                    <tbody>
                      {(maildomain.recipients || []).map(
                        (a: RecipientAlias) => {
                          let alias = a
                          if (!filter_recipient(a))
                            return <tr key={a.id} hidden={true} />
                          return (
                            <tr
                              key={a.id}
                              className={
                                (a.id === selectedRecipient &&
                                  "table-success") ||
                                ""
                              }
                            >
                              <td
                                onClick={() => {
                                  beforeSave()
                                  setSelectedRecipient(a.id)
                                  setSelectedSender(-1)
                                }}
                              >
                                {alias.alias}
                              </td>
                              <td>
                                {/*
                                <img
                                  src="/delete.png"
                                  alt={"delete alias " + alias.alias}
                                  onClick={() => {
                                    const rc = window.confirm(
                                      "Are you sure you want to delete" +
                                        " the recipient " +
                                        alias.alias +
                                        "?"
                                    )
                                    console.log("rc = " + rc)
                                    if (rc) {
                                      setSelectedRecipient(-1)
                                    }
                                  }}
                                />*/}
                              </td>
                            </tr>
                          )
                        }
                      )}

                      <tr>
                        <td>
                          <Field
                            className={"form-control form-control-sm col"}
                            type={"text"}
                            name={"alias"}
                            placeholder={"new alias name"}
                          />
                        </td>
                        <td>
                          <PrimaryButton
                            text={"Add"}
                            isSubmitting={isSubmitting}
                            disabled={!can_add_alias(values)}
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </Form>
              </>
            )}
          </Formik>
        </div>
        {selectedRecipient > -1 && (
          <div className={"col ml-4"}>
            <EditRecipientDetails recipient={recipient} />
          </div>
        )}
      </div>
    </>
  )
}

export default MailDomainEdit
